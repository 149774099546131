import { Button, Card, Group, Input, ScrollArea } from "@mantine/core";
import axios from "axios";
import { useCallback, useEffect, useRef, useState } from "react";
import lodash from "lodash";

function Billy() {
  const [audioList, setAudioList] = useState<any[]>([]);
  const localIpStorage = useRef(
    localStorage.getItem("BILLY_IP") || "https://192.168.177.204:3002"
  );
  const [localIp, setLocalIp] = useState(localIpStorage.current);
  const getAudiolist = useCallback(async () => {
    const { data } = await axios.get(`${localIp}/list`);
    setAudioList(data);
  }, [localIp]);

  const play = useCallback(
    async (key: any) => {
      await axios.get(`${localIp}/audio/${key}`);
    },
    [localIp]
  );

  const stop = useCallback(async () => {
    await axios.get(`${localIp}/stop`);
  }, [localIp]);

  useEffect(() => {
    getAudiolist();
  }, []);

  return (
    <Card
      shadow="sm"
      p="lg"
      radius="md"
      withBorder
      style={{ position: "relative", overflow: "unset" }}
    >
      <Input
        placeholder="local ip"
        value={localIp}
        onChange={(event: any) => {
          setLocalIp(event.target.value);
        }}
      />
      <Group>
        <Button
          size="xs"
          mt={20}
          onClick={() => {
            getAudiolist();
          }}
        >
          Pobierz
        </Button>
        <Button
          size="xs"
          mt={20}
          onClick={() => {
            localStorage.removeItem("BANANA_IP");
          }}
        >
          Usuń z local storage
        </Button>
      </Group>
      z {localIp}
      <div>Ilosc: {Object.keys(audioList).length || 0}</div>
      <br />
          Jezeli nie dziala, to wejdz na{" "}
          <a href={localIp} target="_blank" rel="noreferrer">
            {localIp}
          </a>{" "}
          aby odblokować certyfikat
          <br />
          <br />
          <br />
      <Button color="red" onClick={stop}>
        STOP
      </Button>
      <ScrollArea style={{ height: 400 }}>
        {lodash.map(audioList, (audio, key) => (
          <div
            key={audio.name}
            style={{ display: "flex", marginBottom: "20px" }}
          >
            {audio.name}
            <div style={{ marginLeft: "auto" }}>
              <Button size="xs" onClick={() => play(key)}>
                Graj
              </Button>
            </div>
          </div>
        ))}
      </ScrollArea>
    </Card>
  );
}

export default Billy;
