import {
  Alert,
  AlphaSlider,
  Badge,
  Button,
  Card,
  ColorPicker,
  Group,
  Input,
  List,
  Loader,
  LoadingOverlay,
  Select,
  SimpleGrid,
  Slider,
  Switch,
  Text,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useCallback, useEffect, useRef, useState } from "react";
import io, { Socket } from "socket.io-client";
import Billy from "./Billy";

interface Settings {
  storm: {
    audio: {
      active: boolean;
      volume: number;
      output?: string;
      activeLoading?: boolean;
      volumeLoading?: boolean;
      outputLoading?: boolean;
    };
    light: {
      active: boolean;
      activeLoading?: boolean;
    };
  };
  chimney: {
    audio: {
      active: boolean;
      volume: number;
      output?: string;
      activeLoading?: boolean;
      volumeLoading?: boolean;
      outputLoading?: boolean;
    };
    light: {
      active: boolean;
      activeLoading?: boolean;
    };
    smoke: {
      active: boolean;
    };
  };
  audio: {
    outputs: { name: string; address: string }[];
  };
  cpu: {
    temperature: {
      main: 0;
      max: 0;
    };
  };
  memory: {
    total: 0;
    free: 0;
    used: 0;
    active: 0;
    available: 0;
  };
  usb: {
    CH1: {
      active: boolean;
      activeLoading?: boolean;
    };
    CH2: {
      active: boolean;
      activeLoading?: boolean;
    };
    CH3: {
      active: boolean;
      activeLoading?: boolean;
    };
    CH4: {
      active: boolean;
      activeLoading?: boolean;
    };
    CH5: {
      active: boolean;
      activeLoading?: boolean;
    };
    CH6: {
      active: boolean;
      activeLoading?: boolean;
    };
    CH7: {
      active: boolean;
      activeLoading?: boolean;
    };
    CH8: {
      active: boolean;
      activeLoading?: boolean;
    };
  };
  window: {
    rgb: {
      red: string | number;
      green: string | number;
      blue: string | number;
      white: string | number;
      value: string;
    };
  };
}
const initialSettings: Settings = {
  window: {
    rgb: {
      red: 0,
      green: 0,
      blue: 0,
      white: 0,
      value: "0,0,0,0",
    },
  },
  storm: {
    audio: {
      active: false,
      volume: 0,
    },
    light: {
      active: false,
    },
  },
  chimney: {
    audio: {
      active: false,
      volume: 0,
    },
    light: {
      active: false,
    },
    smoke: {
      active: false,
    },
  },
  audio: {
    outputs: [],
  },
  cpu: {
    temperature: {
      main: 0,
      max: 0,
    },
  },
  memory: {
    total: 0,
    free: 0,
    used: 0,
    active: 0,
    available: 0,
  },
  usb: {
    CH1: {
      active: false,
    },
    CH2: {
      active: false,
    },
    CH3: {
      active: false,
    },
    CH4: {
      active: false,
    },
    CH5: {
      active: false,
    },
    CH6: {
      active: false,
    },
    CH7: {
      active: false,
    },
    CH8: {
      active: false,
    },
  },
};

function Hello() {
  const localIpStorage = useRef(
    localStorage.getItem("BANANA_IP") || "https://192.168.177.203:3009"
  );
  const [localIp, setLocalIp] = useState(localIpStorage.current);
  // const socketRef = useRef<Socket | undefined>();
  const [socket, setSocket] = useState<Socket | undefined>();

  useEffect(() => {
    setSocket(io(localIp));
  }, []);

  // const socket = socketRef?.current;
  const [isConnected, setIsConnected] = useState(socket?.connected);
  const [settings, setSettings] = useState(initialSettings);
  const form = useForm();
  const audioCardOptions = settings.audio.outputs?.map((output, i) => ({
    label: `${i + 1}. ${output.name}`,
    value: output.address,
  }));
  const handleConnect = () => {
    localStorage.setItem("BANANA_IP", localIp);
    setSocket(io(localIp));
    // socketRef.current = io(localIp);
  };

  useEffect(() => {
    socket?.on("connect", () => {
      setIsConnected(true);
    });

    socket?.on("disconnect", () => {
      setIsConnected(false);
    });

    socket?.on("SETTINGS", (settings) => {
      setSettings(settings);
    });

    return () => {
      socket?.off("connect");
      socket?.off("disconnect");
      socket?.off("pong");
    };
  }, [socket, localIp]);

  const handleChimneyChange = useCallback(
    (type: string, field: string, value: string | number | boolean | null) => {
      setSettings({
        ...settings,
        chimney: {
          ...settings.chimney,
          [type]: {
            // @ts-ignore
            ...settings.chimney[type],
            [`${field}Loading`]: true,
          },
        },
      });
      console.log(type, field, value, socket);
      socket?.emit("CHANGE", {
        module: "chimney",
        type,
        value: {
          [field]: value,
        },
      });
    },
    [settings, socket]
  );

  const handleStormChange = (
    type: string,
    field: string,
    value: string | number | boolean | null
  ) => {
    setSettings({
      ...settings,
      storm: {
        ...settings.storm,
        [type]: {
          // @ts-ignore
          ...settings.storm[type],
          [`${field}Loading`]: true,
        },
      },
    });
    socket?.emit("CHANGE", {
      module: "storm",
      type,
      value: {
        [field]: value,
      },
    });
  };
  const handleUsbChange = (
    type: string,
    field: string,
    value: string | number | boolean | null
  ) => {
    setSettings({
      ...settings,
      usb: {
        ...settings.usb,
        [type]: {
          // @ts-ignore
          ...settings.usb[type],
          [`${field}Loading`]: true,
        },
      },
    });
    socket?.emit("CHANGE", {
      module: "usb",
      type,
      value: {
        [field]: value,
      },
    });
  };
  const handleWindowChange = useCallback(
    (red = "0", green = "0", blue = "0", white = "0") => {
      const value = `>${String(red).padStart(3, "0")},${String(green).padStart(
        3,
        "0"
      )},${String(blue).padStart(3, "0")},${String(white).padStart(3, "0")}<`;
      console.log("before_save", settings.window.rgb);
      setSettings({
        ...settings,
        window: {
          // ...settings.window,
          rgb: {
            // ...settings.window.rgb,
            red,
            green,
            blue,
            white,
            value,
            // [`${field}Loading`]: true,
          },
        },
      });
      console.log("emit", value, red, green, blue, white);
      socket?.emit("CHANGE", {
        module: "window",
        type: "rgb",
        red,
        green,
        blue,
        white,
        value,
      });
    },
    [settings, setSettings, socket]
  );
  const onRgbChange = useCallback(
    (value: any) => {
      var color = value;
      var matchColors = /rgb\((\d{1,3}), (\d{1,3}), (\d{1,3})\)/;
      var match = matchColors.exec(color);
      if (match !== null) {
        const red = match[1];
        const green = match[2];
        const blue = match[3];
        console.log("before_rgb", settings.window.rgb);
        handleWindowChange(red, green, blue, String(settings.window.rgb.white));
      }
    },
    [handleWindowChange, settings]
  );
  // console.log('settings', settings.window.rgb);
  const onWindowWhiteChange = useCallback(
    (value: any) => {
      console.log("before_white", settings.window.rgb);
      handleWindowChange(
        String(settings.window.rgb.red || 0),
        String(settings.window.rgb.green || 0),
        String(settings.window.rgb.blue || 0),
        String(value)
      );
    },
    [settings.window.rgb, handleWindowChange, settings]
  );
  const handleWhiteLedSliderEnd = useCallback(
    (value: any) => onWindowWhiteChange(String(value)),
    [onWindowWhiteChange]
  );
  return (
    <div>
      <SimpleGrid
        cols={3}
        breakpoints={[
          { maxWidth: 980, cols: 3, spacing: "md" },
          { maxWidth: 755, cols: 2, spacing: "sm" },
          { maxWidth: 600, cols: 1, spacing: "sm" },
        ]}
      >
        <Card
          shadow="sm"
          p="lg"
          radius="md"
          withBorder
          style={{ position: "relative", overflow: "unset" }}
        >
          {isConnected && <Alert color="green">Połączone</Alert>}
          {!isConnected && <Alert color="red">Nie ma połączenia</Alert>}
          <Input
            placeholder="local ip"
            value={localIp}
            onChange={(event: any) => {
              setLocalIp(event.target.value);
            }}
          />
          <Button onClick={handleConnect} color="green">
            Połącz
          </Button>
          <div>
            <Button
              size="xs"
              mt={20}
              onClick={() => {
                localStorage.removeItem("BANANA_IP");
              }}
            >
              Usuń z local storage
            </Button>
          </div>
          <br />
          <br />
          <br />
          Jezeli nie dziala, to wejdz na{" "}
          <a href={localIp} target="_blank" rel="noreferrer">
            {localIp}
          </a>{" "}
          aby odblokować certyfikat
          <br />
          <br />
          <br />
        </Card>
        {isConnected && (
          <>
            <Card
              shadow="sm"
              p="lg"
              radius="md"
              withBorder
              style={{ position: "relative", overflow: "unset" }}
            >
              <LoadingOverlay
                visible={
                  settings.usb.CH2.activeLoading === true ||
                  settings.usb.CH3.activeLoading === true ||
                  settings.usb.CH4.activeLoading === true ||
                  settings.usb.CH5.activeLoading === true ||
                  settings.usb.CH6.activeLoading === true ||
                  settings.usb.CH7.activeLoading === true ||
                  settings.usb.CH8.activeLoading === true
                }
                overlayBlur={1}
              />
              <Group position="apart" mt="md" mb="xs">
                <Text weight={500}>Oświetlenie</Text>
              </Group>
              <SimpleGrid cols={1}>
                <div style={{ flexDirection: "row", display: "flex" }}>
                  <Switch
                    label="Żyrandol 1"
                    onChange={(event) => {
                      handleUsbChange("CH2", "active", event.target.checked);
                    }}
                    checked={settings.usb.CH2.active}
                    defaultChecked={settings.usb.CH2.active === true}
                  />
                </div>
                <div style={{ flexDirection: "row", display: "flex" }}>
                  <Switch
                    label="Żyrandol 2"
                    onChange={(event) => {
                      handleUsbChange("CH3", "active", event.target.checked);
                    }}
                    checked={settings.usb.CH3.active}
                    defaultChecked={settings.usb.CH3.active === true}
                  />
                </div>
                <div style={{ flexDirection: "row", display: "flex" }}>
                  <Switch
                    label="Front ściana"
                    onChange={(event) => {
                      handleUsbChange("CH4", "active", event.target.checked);
                    }}
                    checked={settings.usb.CH4.active}
                    defaultChecked={settings.usb.CH4.active === true}
                  />
                </div>
                <div style={{ flexDirection: "row", display: "flex" }}>
                  <Switch
                    label="Bar"
                    onChange={(event) => {
                      handleUsbChange("CH5", "active", event.target.checked);
                    }}
                    checked={settings.usb.CH5.active}
                    defaultChecked={settings.usb.CH5.active === true}
                  />
                </div>
                <div style={{ flexDirection: "row", display: "flex" }}>
                  <Switch
                    label="Kinkiety"
                    onChange={(event) => {
                      handleUsbChange("CH6", "active", event.target.checked);
                    }}
                    checked={settings.usb.CH6.active}
                    defaultChecked={settings.usb.CH6.active === true}
                  />
                </div>
                <div style={{ flexDirection: "row", display: "flex" }}>
                  <Switch
                    label="CH7"
                    onChange={(event) => {
                      handleUsbChange("CH7", "active", event.target.checked);
                    }}
                    checked={settings.usb.CH7.active}
                    defaultChecked={settings.usb.CH7.active === true}
                  />
                </div>
                <div style={{ flexDirection: "row", display: "flex" }}>
                  <Switch
                    label="Okna białe światło"
                    onChange={(event) => {
                      handleUsbChange("CH8", "active", event.target.checked);
                    }}
                    checked={settings.usb.CH8.active}
                    defaultChecked={settings.usb.CH8.active === true}
                  />
                </div>
              </SimpleGrid>
            </Card>
            <Card
              shadow="sm"
              p="lg"
              radius="md"
              withBorder
              style={{ position: "relative", overflow: "unset" }}
            >
              <LoadingOverlay
                visible={
                  settings.chimney.audio.outputLoading ||
                  settings.usb.CH1.activeLoading ||
                  settings.chimney.audio.activeLoading ||
                  settings.chimney.audio.volumeLoading ||
                  settings.chimney.light.activeLoading
                    ? true
                    : false
                }
                overlayBlur={1}
              />
              <Group position="apart" mt="md" mb="xs">
                <Text weight={500}>Kominek</Text>
              </Group>
              <SimpleGrid cols={1}>
                <div style={{ flexDirection: "row", display: "flex" }}>
                  <Select
                    label="Wyjście audio"
                    data={audioCardOptions}
                    onChange={(value) => {
                      handleChimneyChange("audio", "output", value);
                    }}
                    value={
                      settings.chimney.audio.output
                        ? audioCardOptions.find(
                            (option) =>
                              option.value === settings.chimney.audio.output
                          )?.value
                        : undefined
                    }
                  />
                  {/* {settings.chimney.audio.outputLoading && <Loader size={20} />} */}
                </div>
                <div style={{ flexDirection: "row", display: "flex" }}>
                  <Switch
                    label="Podświetlenie"
                    onChange={(event) => {
                      handleUsbChange("CH1", "active", event.target.checked);
                      // handleChimneyChange("light", "active", event.target.checked);
                    }}
                    checked={settings.usb.CH1.active}
                    defaultChecked={settings.usb.CH1.active === true}
                  />
                  {/* {settings.chimney.light.activeLoading && <Loader size={20} />} */}
                </div>
                <div style={{ flexDirection: "row", display: "flex" }}>
                  <Switch
                    label="Dźwięk kominka"
                    onChange={(event) => {
                      handleChimneyChange(
                        "audio",
                        "active",
                        event.target.checked
                      );
                    }}
                    checked={settings.chimney.audio.active}
                    defaultChecked={settings.chimney.audio.active === true}
                  />
                  {/* {settings.chimney.audio.activeLoading && <Loader size={20} />} */}
                </div>
                <div>
                  Głośność
                  <Slider
                    mb={20}
                    marks={[
                      { value: 20, label: "20%" },
                      { value: 50, label: "50%" },
                      { value: 80, label: "80%" },
                    ]}
                    onChangeEnd={(value) => {
                      handleChimneyChange("audio", "volume", value);
                    }}
                    value={settings.chimney.audio.volume}
                  />
                  {/* <Loader size={20} /> */}
                </div>
              </SimpleGrid>
            </Card>
            <Card
              shadow="sm"
              p="lg"
              radius="md"
              withBorder
              style={{
                position: "relative",
                overflow: "unset",
                display: "none",
              }}
            >
              <LoadingOverlay
                visible={
                  settings.storm.audio.outputLoading ||
                  settings.storm.audio.activeLoading ||
                  settings.storm.audio.volumeLoading ||
                  settings.storm.light.activeLoading
                    ? true
                    : false
                }
                overlayBlur={1}
              />
              <Group position="apart" mt="md" mb="xs">
                <Text weight={500}>Burza</Text>
              </Group>
              <SimpleGrid cols={1}>
                <div style={{ flexDirection: "row", display: "flex" }}>
                  <Select
                    label="Wyjście audio"
                    data={audioCardOptions}
                    onChange={(value) => {
                      handleStormChange("audio", "output", value);
                    }}
                    value={
                      settings.storm.audio.output
                        ? audioCardOptions.find(
                            (option) =>
                              option.value === settings.storm.audio.output
                          )?.value
                        : undefined
                    }
                  />
                </div>
                <div style={{ flexDirection: "row", display: "flex" }}>
                  <Switch
                    label="Podświetlenie"
                    onChange={(event) => {
                      handleStormChange(
                        "light",
                        "active",
                        event.target.checked
                      );
                    }}
                    checked={settings.storm.light.active}
                    defaultChecked={settings.storm.light.active === true}
                  />
                  {/* {settings.chimney.light.activeLoading && <Loader size={20} />} */}
                </div>
                <div style={{ flexDirection: "row", display: "flex" }}>
                  <Switch
                    label="Dźwięk burzy"
                    onChange={(event) => {
                      handleStormChange(
                        "audio",
                        "active",
                        event.target.checked
                      );
                    }}
                    checked={settings.storm.audio.active}
                    defaultChecked={settings.storm.audio.active === true}
                  />
                </div>
                <div>
                  Głośność
                  <Slider
                    mb={20}
                    marks={[
                      { value: 20, label: "20%" },
                      { value: 50, label: "50%" },
                      { value: 80, label: "80%" },
                    ]}
                    onChangeEnd={(value) => {
                      handleStormChange("audio", "volume", value);
                    }}
                    value={settings.storm.audio.volume}
                  />
                </div>
              </SimpleGrid>
            </Card>

            <Card shadow="sm" p="lg" radius="md" withBorder>
              <Group position="apart" mt="md" mb="xs">
                <Text weight={500}>Okna</Text>
              </Group>
              <List>
                <ColorPicker format="rgb" onChangeEnd={onRgbChange} />
                <br />
                Kolor biały
                <Slider
                  mb={20}
                  marks={[
                    { value: 20, label: "20%" },
                    { value: 50, label: "50%" },
                    { value: 80, label: "80%" },
                  ]}
                  onChangeEnd={handleWhiteLedSliderEnd}
                  value={Number(settings.window.rgb.white)}
                />
                <br />
                <Group>
                  <Button onClick={() => onWindowWhiteChange("100")}>
                    Włącz białe 100%
                  </Button>
                  <Button
                    onClick={() => handleWindowChange("0", "0", "0", "0")}
                  >
                    Wyłącz całe
                  </Button>
                  <Button
                    onClick={() =>
                      handleWindowChange(
                        String(settings.window.rgb.red || 0),
                        String(settings.window.rgb.green || 0),
                        String(settings.window.rgb.blue || 0),
                        "0"
                      )
                    }
                  >
                    Wyłącz biały
                  </Button>
                  <Button
                    onClick={() =>
                      handleWindowChange(
                        "0",
                        "0",
                        "0",
                        String(settings.window.rgb.white || 0)
                      )
                    }
                  >
                    Wyłącz kolor
                  </Button>
                </Group>
              </List>
            </Card>
          </>
        )}
        <Billy />
        {isConnected && (
          <Card shadow="sm" p="lg" radius="md" withBorder>
            <Group position="apart" mt="md" mb="xs">
              <Text weight={500}>Urządzenia audio</Text>
            </Group>
            <List>
              {settings.audio.outputs.map((output, i) => {
                return (
                  <List.Item key={i}>
                    {i + 1}. {output.name}
                  </List.Item>
                );
              })}
            </List>
          </Card>
        )}
      </SimpleGrid>
    </div>
  );
}

export default Hello;
